html,body {
  background-color: black;
}
.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.cinzel {
  font-family: 'Cinzel', serif;
}
.montserrat {
  font-family: 'Montserrat', sans-serif;
}
.grotzec {
  font-family: 'Grotzec', sans-serif;
}
.grotzec-light {
  font-family: 'Grotzec-light', sans-serif;
}

.appear-enter {
  opacity: 0.01;
  /* transform: translate(0,-40px); */
  height: 0;
}

.appear-enter-active {
  opacity: 1;
  height: auto;
  /* transform: translate(0, 0); */
  transition: opacity 500ms ease-in, height 500ms ease-in;
}

.appear-exit {
  opacity: 1;
  height: auto;
  transform: translate(0, 0)
}

.appear-exit-active {
  opacity: 0.01;
  height: 0;
  /* transform: translate(0,40px); */
  transition: opacity 500ms ease-in, height 500ms ease-in;
}
.loading-banner {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 100;
}
.loading-title {
  font-size:7vw;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: scale(1) translate(-50%,-50%);
  color: white;
}
.loading-animation {
  animation: loading 8s ease-in;
  animation-iteration-count: infinite;
}
@keyframes loading {
  0% {
     /* -webkit-transform: scale(1); */
     transform: translate(-50%,-50%) scale(1);
     /* font-size:7vw; */
    opacity: 1;
  }
  40%,50% {
    /* font-size: 8vw; */
     /* -webkit-transform: translate(-50%,-50%) scale(1.5); */
     transform: translate(-50%,-50%) scale(1.1);
     opacity: 0.01;
   }
 }

.ImgBack1{
  position: absolute;
  width: 1920px;
  height: 1080px;
  background-size: cover;
  top: 0;
  left: 0;
}

.MContainer{
  position: absolute;
  overflow: hidden;

  /* transition: 700ms all ease-in; */
}


/* .MContainerZoom {
  transform: scale(1.2);
  /* transform-origin: 8% 40%; */
  /* transition: 700ms all ease-in;
} */

/* .MContainerZoom1 {
  transform: scale(1.4);
  transform-origin: 28% 60%;
  transition: 700ms all ease-in; 
} */

.VContainer{
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
 .react-player video {
  object-fit: cover;
 }
.CenterInMContainer{
  position: absolute;
  left: 536px;
  top: 300px;


}
@keyframes blinker {
  75% {
    opacity: 0;
  }
}
.BgLoading{
  animation: blinker 3s linear infinite;
}

.BgHide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}
.sound-icon-holder {
  position: absolute;
  top: 10px;
  left: 10px;
  /* width: 28px;
  height: 30px; */
}
.sound-icon {
  color: rgba(255,255,255,0.7);
  font-size: 30px;
  background: rgba(0,0,0,0.2);
  border-radius: 100%;
  padding: 5px;
  cursor: pointer;
  transform: scale(1);
  transition: 0.2s all ease-in;
}
.sound-icon:hover {
  transform: scale(1.1);
}

.Mark {
  position: absolute;
  cursor: pointer;
  outline: none;
  border: none;
  background: rgba(221, 221, 221, 0.25);
  border-radius: 100%;
  transition: transform .2s; 
  -webkit-animation: pulse2 20s linear;
  -moz-animation: pulse2 20s linear;
  animation: pulse2 20s linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  box-shadow: 0 0 15px rgba(221, 221, 221,0.5);
  opacity: 0;
}
.Mark:focus {
  outline: none;
}
.Mark:hover{
  background: rgba(221, 221, 221, 0.1);
  box-shadow: 0 0 15px rgba(221, 221, 221,0.1);
  transform: scale(2.2);
  transition: 0.2s all ease;
  animation: none;
  opacity: 1;
}
.Mark-360,.Mark-360:hover {
  background: none;
}

.MarkI {
  position: absolute;
  cursor: pointer;
  outline: none;
  border: none;
  background: rgba(221, 221, 221, 0.25);
  border-radius: 100%;
  transition: transform .2s;
  opacity: 0;
  -webkit-animation: pulse 20s linear;
  -moz-animation: pulse 20s linear;
  animation: pulse 20s linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  box-shadow: 0 0 15px rgba(221, 221, 221,0.5);
}
.MarkI:focus {
  outline: none;
}
.MarkI:hover{
  background: rgba(221, 221, 221, 0.1);
  box-shadow: 0 0 15px rgba(221, 221, 221,0.1);
  transform: scale(1.05);
  transition: 0.2s all ease;
  animation: none;
  opacity: 1;
}
@keyframes pulse {
  0% {
     -webkit-transform: scale(1);
    opacity: 0;
  }
   20% {
     -webkit-transform: scale(1.5);
     opacity: 1;
   }
   45% {
     -webkit-transform: scale(2);
     opacity: 0.15;
   } 
   50% {
     -webkit-transform: scale(2);
     opacity: 0.6;
   }
   55% {
     -webkit-transform: scale(2);
     opacity: 0.15;
   }
   80% {
     -webkit-transform: scale(1.5);
     opacity: 0.5;
   }
   100% {
    -webkit-transform: scale(1);
    opacity: 0;
 }
 }
@keyframes pulse2 {
  0%,100% {
    transform: scale(1);
    opacity: 0;
  }
   20% {
    transform: scale(1.5);
     opacity: 0.5;
   }
   45% {
    transform: scale(2);
     opacity: 0.15;
   } 
   50% {
    transform: scale(2);
     opacity: 0.6;
   }
   55% {
    transform: scale(2);
     opacity: 0.15;
   }
   80% {
    transform: scale(1.5);
     opacity: 0.5;
   }
 }

.container {
  max-width: 1280px;
  padding: 40px;
  padding-top: 60px;
  margin: 0 auto;
  color: white;
  z-index: 100;
}

.flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.credits-holder {
  flex-basis: 40%;
  text-align:justify;
}
.credits-holder a {
  color: white;
  text-decoration:underline;
}
.credits-title {
  font-size:40px;
  flex-basis: 100%;
}
.mb-0 * {
    margin-bottom: 0;
}
.center {
  text-align: center;
}
.credits-overlay {
  background: linear-gradient(180deg, rgba(16,24,35,0) 50%, rgba(0,0,0,1) 100%);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
}
.credits-section {
  height: auto;
  min-height:100vh;
  background-color: rgb(0,0,0);
  /* background-color: linear-gradient(180deg, rgba(16,24,35,0) 0%, rgba(0,0,0,1) 100%); */
}

@media screen and (orientation: portrait) {
  .RotaryAnim {
    visibility: visible;
  }
  .MarkI {
    visibility: hidden;
    position: absolute;
  }
}

/* For landscape, we want the tool bar stick on the left */

@media screen and (orientation: landscape) {
  .RotaryAnim {
    visibility: hidden !important;
  }
  .MarkI{
    position: absolute;
    cursor: pointer;
    outline: none;
    border: none;
    background: rgba(221, 221, 221, 0.25);
    border-radius: 100%;
    transition: transform .2s;
    -webkit-animation: pulse 20s linear;
    -moz-animation: pulse 20s linear;
    animation: pulse 20s linear;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    box-shadow: 0 0 15px rgba(221, 221, 221,0.5);
  }
  .MarkI:focus {
    outline: none;
  }

}
.rotate {
  animation: spin 2.5s infinite;
  font-size:60px;
}

@keyframes spin {
  0%,100% { transform: rotate(45.5deg); }
  40% { transform: rotate(136deg); }
}

.RotaryIco {
  position: absolute;
  color: #FFFFFF80;
  top: 70%;
  left: calc(50% - 30px)
}

.cineola-holder,.festivales-holder {
  flex-basis: 100%;
  width: 100%;
  z-index: 100;
}

.cineola-holder img {
  width: 15px;
  min-width: 150px;
}
.festivales-holder img {
  width: 50%;
  min-width: 250px;
}

.Snap{
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  background-color: #000000;
}
@media(max-width:768px) {
  .credits-holder {
    flex-basis: 100%;
    margin-bottom: 40px!important;
  }
}
html {
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
}
section {
  height: 100vh;
  scroll-snap-align: start;
  text-align: center;
  position: relative;
}

.loading-button {
  position: absolute;
  top: 60%;
  height: 50px;
    width: 50px;
    border: none;
  background: rgba(221, 221, 221, 0.25);
  border-radius: 100%;
  box-shadow: 0 0 15px rgba(221, 221, 221,0.5);
  animation: loading-2 8s ease-in;
  animation-iteration-count: infinite;
}
@keyframes loading-2 {
  0% {
    opacity: 1;
  }
  40%,50% {
     opacity: 0.01;
   }
 }
